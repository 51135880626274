<template>
    <kk-modal-v2
        v-if="desktop"
        class="kk-confirm-desktop"
        @cancel="$emit('cancel')"
    >
        <template #title>
            {{ title }}
        </template>
        <template #content>
            <span>
                {{ text }}
            </span>
        </template>
        <template #footer>
            <kk-button
                v-if="cancelButtonText !== false"
                class="cancel-button"
                type="basic"
                @click="$emit('cancel')"
            >
                <template v-if="cancelButtonText">
                    {{ cancelButtonText }}
                </template>
                <template v-else>
                    {{ trans("shared.Avbryt") }}
                </template>
            </kk-button>

            <kk-button
                :type="confirmButtonType"
                @click="$emit('confirm', confirmId)"
            >
                {{ confirmButtonText }}
            </kk-button>
        </template>
    </kk-modal-v2>
    <kk-card
        v-else
        :margin="0"
        class="kk-confirm-mobile"
    >
        <header>
            <h2>
                {{ title }}
            </h2>
        </header>
        <span>
            {{ text }}
        </span>
        <footer>
            <kk-button
                v-if="cancelButtonText !== false"
                big
                type="basic"
                full
                @click="$emit('cancel');"
            >
                <template v-if="cancelButtonText">
                    {{ cancelButtonText }}
                </template>
                <template v-else>
                    {{ trans('shared.Gå tilbake') }}
                </template>
            </kk-button>
            <kk-button
                big
                full
                :type="confirmButtonType"
                @click="$emit('confirm', confirmId);"
            >
                {{ confirmButtonText }}
            </kk-button>
        </footer>
    </kk-card>
</template>

<script>
import { kkCard } from 'vue-components';
import kkModalV2 from '../kk-modal-v2/kk-modal-v2.vue';
import KkButton from '../kk-button/kk-button.vue';
import trans from '../../filters/trans.js';

export default {
    name: 'kk-confirm',
    components: {
        KkButton,
        kkCard,
        kkModalV2,
    },
    props: {
        desktop: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
            required: true,
        },
        text: {
            type: String,
            default: '',
            required: true,
        },
        // In case of a confirmation, this is the id that'll be affected & returned from the `confirm` event
        confirmId: {
            type: Number,
            default: null,
        },
        confirmButtonText: {
            type: String,
            default: trans('shared.OK'),
        },
        confirmButtonType: {
            default: 'primary',
            type: String,
            validator: (value) => {
                return [
                    'basic',
                    'clean',
                    'danger',
                    'default',
                    'link',
                    'primary',
                    'success',
                    'warning',
                    'transparent',
                ].includes(value);
            },
        },
        cancelButtonText: {
            type: [String, Boolean],
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables.scss';

.kk-confirm-mobile {
    header {
        h2 {
            margin-top: 0;
        }
    }

    span {
        color: $text-medium-emphasis;
    }

    footer {
        display: flex;
        gap: 20px;
        padding: 0;
        margin: 20px 0 0 0;
        background-color: unset;

        .kk-button {
            width: 100%;
        }
    }
}

.kk-confirm-desktop::v-deep {
    font-family: "Work Sans", sans-serif;

    .dialogbox {
        width: 384px;

        header {
            padding: 0 0 20px 0 !important;
        }

        .content-section {
            span {
                margin: 0 0 20px 0;
                color: $text-medium-emphasis;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.001px;
            }
        }

        .footer {
            padding: 0;
            display: flex;
            justify-content: flex-end;
        }

        .footer:has(.cancel-button) {
            justify-content: space-between;
        }
    }
}

</style>
